// fonts
import "@fontsource/playfair-display/700.css"
import "@fontsource/montserrat/400.css"
import "@fontsource/montserrat/600.css"
import "@fontsource/montserrat/500.css"
import "@fontsource/montserrat/700.css"

// custom CSS styles
import './src/styles/global.css'

export const shouldUpdateScroll = ({
    routerProps: { location },
    getSavedScrollPosition
}) => {
    const currentPosition = getSavedScrollPosition(location)

    if (location?.pathname?.includes('entertainment') || location?.hash?.length > 0 ) {
    } else {
        window.scrollTo(...(currentPosition || [0, 0]))
    }

    return false
}